import { Link, Head } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

export default function Welcome(props) {
    const { t } = useTranslation();
    return (
        <>
            <Head title="Welcome" />
            <div className='welcome'>
                {props.auth.user ? (
                    <div className='flex justify-end'>
                        <Link href={route('machines')} className="mt-4 mr-4 bg-primary hover:bg-green-700 font-bold text-sm text-white border-2 py-1 px-4 rounded-full">
                            {t('menu.machines')}
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className='flex justify-end'>
                            <Link href={route('login')} className="mt-4 mr-4 bg-primary hover:bg-green-700 font-bold text-sm text-white border-2 py-1 px-4 rounded-full">
                                Log in
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
